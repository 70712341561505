import { NgModule } from '@angular/core';

import { UserHasRechtPipe } from './has-recht.pipe';
import { UserInfoFacade } from './user-info.facade';

@NgModule({
  declarations: [UserHasRechtPipe],
  exports: [UserHasRechtPipe],
  providers: [UserInfoFacade],
})
export class UserInfoModule {}
